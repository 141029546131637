console.log('script');

import '@base_js/vendors/jquery-autocomplete/src/jquery.autocomplete'

import {
  beforePophover,
  afterPophover,
  minHeightFix,
  userMinHeightFix,
  createBirthDateSelect,
  deparam
} from '@base_js/utils'

function script(){
    console.log('generic script')
    var w = $(window),
        d = $(document),
        b = $('body'),
        h = $('html'),
        page = $('html, body'),
        header = $('#header'),
        equalheight = function(elements) {
            var currentTallest = 0,
                currentRowStart = 0,
                rowDivs = [],
                elem,
                topPosition, currentDiv;

            elements.each(function() {
                elem = $(this);
                $(elem).height('auto');
                topPosition = elem.position().top;

                if (currentRowStart != topPosition) {
                    for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) rowDivs[currentDiv].height(currentTallest);
                    rowDivs.length = 0;
                    currentRowStart = topPosition;
                    currentTallest = elem.height();
                    rowDivs.push(elem);
                } else {
                    rowDivs.push(elem);
                    currentTallest = (currentTallest < elem.height()) ? (elem.height()) : (currentTallest);
                }
                for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) rowDivs[currentDiv].height(currentTallest);
            });
        };



    header.find('.menu-toggle').on('click', function() {
        header.toggleClass('open');
        return false;
    });

    header.find('.account-toggle').on('click', function() {
        var box = $(this).parent();
        box.toggleClass('account-open');
        if (box.hasClass('account-open')) d.on('click.account', function(e) {
            var t = $(e.target);
            if (!t.is(box) && !t.closest('.account-open').is(box)) {
                box.removeClass('account-open');
                d.off('click.account');
            }
        });
        return false;
    });

    $('.language-selector').each(function() {
        var box = $(this);
        box.find('.current').on('click', function() {
            box.toggleClass('language-selector-open');
            if (box.hasClass('language-selector-open')) d.on('click.language', function(e) {
                var t = $(e.target);
                if (!t.is(box) && !t.closest('.language-selector-open').is(box)) {
                    box.removeClass('language-selector-open');
                    d.off('click.language');
                }
            });
            return false;
        });
    });

    var loginPopup = $('#login-popup');

    if (loginPopup.length) {
        var showLoginPopupPanel = function(activeSel) {
            loginPopup.find('> .panels').removeClass('panel-active');
            loginPopup.find(activeSel).addClass('panel-active');
        };

        loginPopup.find('.switch-tab-link').on('click', function() {
            showLoginPopupPanel($(this).attr('href'));
            return false;
        });

        var loginForm = !!loginPopup.find('.login-form').length ? loginPopup.find('.login-form') : loginPopup.find('.new-hf-login-form'),
            recoveryForm = loginPopup.find('.password-recovery-form');


        loginForm.on('ajax-submit', function() {
            console.log('ajax-submit');
            var msg = loginForm.find('.msg-error');
            msg.addClass('hidden').text('');
            $.ajax({
                data: loginForm.serialize(),
                method: 'POST',
                url: loginForm.attr('action')
            }).done(function(data) {
                console.log("done", arguments);
                if (data.success) {
                    if (data.redirect_to && window.allow_redirect) {
                        location.href = data.redirect_to;
                    } else {
                        console.log(location);
                        location.reload();
                    }
                } else {
                    loginForm.find('.input-text').addClass('error');
                    msg.text(data.msg ? data.msg : msg.data('default')).removeClass('hidden');
                }
            }).fail(function() {
                console.log("fail", arguments);

                loginForm.find('.input-text').addClass('error');
                msg.text(msg.data('default')).removeClass('hidden');
            }).always(function(a, textStatus, b) {
                console.log("always", arguments);
            });
            return false;
        });

        recoveryForm.on('ajax-submit', function() {
            var msg = recoveryForm.find('.msg-error');
            msg.addClass('hidden').text('');

            $.ajax({
                data: recoveryForm.serialize(),
                method: 'POST',
                url: recoveryForm.attr('action')
            }).done(function(data) {
                if (data.success) showLoginPopupPanel('#login-popup-recovery-ok');
                else {
                    recoveryForm.find('.input-text').addClass('error');
                    if (data.response === 'no user') {
                        msg.text(data.msg ? data.msg : msg.data('neuser')).removeClass('hidden');
                    } else {
                        msg.text(data.msg ? data.msg : msg.data('default')).removeClass('hidden');
                    }
                }
            }).fail(function() {
                recoveryForm.find('.input-text').addClass('error');
                msg.text(msg.data('default')).removeClass('hidden');
            });
            return false;
        });

        if (!$('.mobileDevice').length || $(window).width() > 767) {
            $('.js-open-login-popup').each(function(i, v) {
                var link = $(v),
                    opts = {};

                opts = {
                    type: 'inline',
                    removalDelay: 300,
                    mainClass: 'my-mfp-zoom-in my-mfp-login-popup',
                    midClick: true,
                    closeMarkup: '<button type="button" class="mfp-close icon icon-close-l"></button>',
                    callbacks: {
                        open: function() {
                            setTimeout(function() {
                                $('.mfp-wrap').css('overflow', 'auto')
                                $('.mfp-wrap').css('height', '100%')
                                $('.mfp-wrap').css('-webkit-overflow-scrolling', 'touch')
                            }, 100)

                            var childPopup = !!$(v).data('step') ? $(v).data('step') : '#login-popup-login';
                            if (!childPopup.startsWith('#')) {
                                childPopup = '#' + childPopup;
                            }
                            showLoginPopupPanel(childPopup);
                        },
                        beforeOpen: function() {
                            console.log(beforePophover);
                            beforePophover();
                        },
                        afterClose: function() {
                            afterPophover();
                        }
                    }
                };
                $(link).magnificPopup(opts);
            });
        } else {
            $('.js-open-login-popup').on('click', function(e) {
                e.preventDefault();
                beforePophover();
                TweenMax.fromTo($("#login-popup"), 0.5, { autoAlpha: 0, right: '-100%' }, { autoAlpha: 1, right: 0 });
            });
            $('.js-mobileCloseButton').on('click', function(e) {
                e.preventDefault();
                TweenMax.fromTo($("#login-popup"), 0.5, { autoAlpha: 1, right: '0' }, { autoAlpha: 0, right: '-100%' });
                afterPophover();
            });
        }
    }

    var elements = $('.search-project-autocomplete');
    console.log('searchProjectAutocomplete')
    elements.each((i,v)=>{
        let searchProjectAutocomplete = $(v);
        searchProjectAutocomplete.autocomplete({
            serviceUrl: searchProjectAutocomplete.data('service'),
            paramName: searchProjectAutocomplete.attr('name'),
            minChars: 3,
            dataType: 'json',
            triggerSelectOnValidInput: false,
            maxHeight: 170,
            transformResult: function(response) {
                return { suggestions: $.map(response, function(item) { return { value: item.title, data: item.url }; }) };
            },
            onSelect: function(suggestion) {
                window.location = suggestion.data;
            }
        });
    })

    $('.homepageNs .js-search').on('click', function(e){
        e.preventDefault();
        var $el = e.currentTarget,
            $form = $($el.closest('form'));
        if($form.hasClass('open') && !!$form.find('input').val()){
            $form.submit()
        }else{
            $form.toggleClass('open')
        }
    });

    $('.js-std-form-validation').each(function() {
        var form = $(this),
            showErrors = form.data('visible-errors');
        $.validator.setDefaults({
            ignore: []
        });
        var options = {
            errorElement: 'em',
            /*onkeyup: false,
            onclick: false*/
            rules: {
                society: {
                    required: false
                },
                society_description: {
                    required: false
                },
                'registration-birth_date':{
                    required: true
                }
                /*
                'registration-birth_date': {
                    required: function(element) {
                        var accountType = $("input[name=registration-account_type]:checked").val();
                        return accountType === 'private';
                    }
                }
                */
            }
        };
        form.find('[data-equalTo]').each(function() {
            var field = $(this);
            options.rules[field.attr('name')] = { equalTo: field.attr('data-equalTo') };
        });
        
        if (showErrors) {
            options.errorPlacement = function(error, element) {
                error.appendTo(element.parent());
            }
        } else {
            $('<div class="form-errors hidden"></div>').prependTo(form);
            options.errorLabelContainer = form.find('.form-errors');
        }

        if (form.hasClass('ajax-form') || form.hasClass('new-hf-ajax-form') ) options.submitHandler = function() {
            form.trigger('ajax-submit');
            return false;
        };
        console.log('js-std-form-validation validate call script.js')
        form.validate(options);
    });

    w.on('load', function() {
        $('.std-carousel').each(function() {
          console.log('flickity')
          if(h.hasClass('touch')){
            var $container = $(this),
                $slider = $container.find('> .items');
            $container.addClass('noFLK');
            $container.attr('style', 'height:'+$container.css('height')+'px; margin-right: -25px !important');
            /*$container.css('margin-right', '-25px !important');*/
            /*$container.css('overflow','hidden');*/
            /*$container.css('height',$container.css('height'));*/
            $slider.css('padding-bottom','20px')
          }else{
            var container = $(this),
                slider = container.find('> .items');

            slider.flickity({
                cellSelector: '.item',
                cellAlign: 'left',
                contain: true,
                pageDots: false,
                groupCells: true,
                freeScroll: h.hasClass('touch')
            });

          }
          $(this).removeClass('alphaPreload').prev('.std-carousel-title').removeClass('alphaPreload');

        });
    });

    w.on('load', function() {
        $('.fullscreen-carousel .carousel').each(function() {
        var container = $(this),
            slider = container;
        console.log('fullscreen flickity')
        var $carousel = slider.flickity({
            cellSelector: '.carousel-cell',
            imagesLoaded: true,
            cellAlign: 'left',
            contain: true,
            pageDots: false,
            groupCells: true,
            draggable: false,
            wrapAround: true,
            prevNextButtons: false,
            freeScroll: h.hasClass('touch')
        });

        $('.js-fullscreen-prev').on( 'click', function(e) {
            e.preventDefault()
            $carousel.flickity('previous');
        });
        // next
        $('.js-fullscreen-next').on( 'click', function(e) {
            e.preventDefault()
            $carousel.flickity('next');
        });

        $(container).parent().removeClass('alphaPreload')
        });
    });

    w.on('load resize', function() {
        equalheight($('.box-icon'));
        equalheight($('.how-box > .title'));
        equalheight($('.how-box > .text'));
        equalheight($('.service-box > .title'));
        equalheight($('.service-box > .text'));
    });

    $('.scroll_to_element').on('click', function() {
        var a = $(this),
            dest = $(a.attr('href')),
            top = a.data('zero') ? 0 : dest.offset().top;

        if (dest.length || a.data('zero')) page.stop().animate({ scrollTop: top }, 800, 'swing');
        return false;
    });

    $('.js-gdprAccept').on('click', function(e){
        e.preventDefault();
        var target = $(e.currentTarget);
        $.ajax({
            url: target.data('endpoint'),
            method: 'post',
            dataType: 'json',
            data: JSON.stringify({
                user: target.data('id'),
                is_accepted: true
            }),
            contentType: 'application/json',
            error: function( jqXHR, textStatus, errorThrown){
            	console.log("fail", errorThrown);
            },
            success: function(data, textStatus, jqXHR){
            	$('.js-gdprBanner').detach();
            }
        });
    });
    if(!!$('.js-electronicBillBanner').length){
        var expired = $('.js-electronicBillBanner').data('expired'),
            obj = {
                year        : expired.split(" ")[0].split("-")[0],
                month       : expired.split(" ")[0].split("-")[1],
                day         : expired.split(" ")[0].split("-")[2],
                hours       : expired.split(" ")[1].split(":")[0],
                minutes     : expired.split(" ")[1].split(":")[1],
            },
            expireDate = new Date(obj.year, obj.month, obj.day, obj.hours, obj.minutes),
            cookieDate = parseInt(PdbCookielaw.getCookie('electronic_billing_banner_close'));
        if(!!cookieDate){
            cookieDate = new Date(cookieDate);
            if(cookieDate < expireDate){
                $('.js-electronicBillBanner').detach();
            }
        }
    }
    $('.js-closeBanner').on('click', function(e){
        e.preventDefault();
        var target = $(e.currentTarget);
        var d = new Date();
        PdbCookielaw.createCookie('electronic_billing_banner_close', d.getTime(), 365);
        $('.js-electronicBillBanner').detach();
    });
    
    // /* REWORK 15/03 */

    // /* caricamento thumbnail da youtube partendo dall'id del video */
    // $.each($('.carouselWrapper .project-item'), function(k, v){
    //     var item = $(v),
    //         imgItem = $('.videoThumb', item),
    //         url = imgItem.data('video_url'),
    //         videoId = !!url ? url.substring(url.indexOf('/embed/')+7, url.indexOf('?')) : '';

    //     if(!!videoId){
    //         var img = new Image();
    //         img.onload = function(){
    //             imgItem.attr('src', img.src);
    //         };
    //         img.src = "http://img.youtube.com/vi/"+videoId+"/mqdefault.jpg";
    //     }
    // });
};

export {
  beforePophover,
  afterPophover,
  minHeightFix,
  userMinHeightFix,
  createBirthDateSelect,
  deparam,
  script
}