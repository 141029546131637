console.log('home');


import { script } from '@base_js/script'

// Styles
import '@root/fonts/icomoon/style.css'
import '@custom_vendors/flickity/flickity.min.css'
import '@root/less/base.less'
import '@root/less/end.less'
import '@root/less/common.less'

script()
